<template>
  <div class="freshdeskUserTicketList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div id="accordion">
        <div
          class="card"
          style="padding: 0px;"
        >
          <div
            id="headingOne"
          >
            <h5 class="mb-0">
              <p
                style="cursor:pointer;"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                @click="setColapse()"
              >
                <font-awesome-icon
                  v-if="!colapse"
                  class="mr-1"
                  icon="arrow-alt-circle-down"
                />
                <font-awesome-icon
                  v-else
                  class="mr-1"
                  icon="arrow-alt-circle-up"
                />
                <span>
                  Statistic
                </span>
              </p>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div
              class="card-body"
              style="padding: 0px;"
            >
              <p style="text-align: center; font-size: 20px;">
                <b>Total Count: {{ filteredTickets.length }}</b>
              </p>
              <div class="container-fluid">
                <div class="row">
                  <div
                    v-if="topicTotal > 0"
                    class="col-xl-6 col-lg-6 col-12"
                  >
                    <kendo-chart
                      ref="topicChart"
                      :title-text="`Ticket topic (${topicTotal})`"
                      :title-position="'top'"
                      :chart-area-background="''"
                      :legend-visible="false"
                      :series-defaults-labels-visible="true"
                      :series-defaults-labels-background="'transparent'"
                      :series-defaults-labels-template="labelTemplate"
                      :tooltip-template="tooltipTemplate"
                      :series-defaults-labels-position="'insideBase'"
                      :series="topicSeries"
                      :tooltip-visible="true"
                      :theme="'sass'"
                    />
                  </div>
                  <div
                    v-if="prioTotal"
                    class="col-xl-6 col-lg-6 col-12"
                  >
                    <kendo-chart
                      ref="prioChart"
                      :title-text="`Ticket priority (${prioTotal})`"
                      :title-position="'top'"
                      :chart-area-background="''"
                      :legend-visible="false"
                      :series-defaults-labels-visible="true"
                      :series-defaults-labels-background="'transparent'"
                      :series-defaults-labels-template="labelTemplate"
                      :tooltip-template="tooltipTemplate"
                      :series-defaults-labels-position="'insideBase'"
                      :series="prioSeries"
                      :tooltip-visible="true"
                      :theme="'sass'"
                    />
                  </div>
                  <div
                    v-if="createdTotal > 0"
                    class="col-xl-6 col-lg-6 col-12"
                  >
                    <kendo-chart
                      ref="createdChart"                    
                      :title-text="`Tickets created on Calendar Weeks (${createdTotal})`"
                      :title-position="'top'"
                      :chart-area-background="''"
                      :legend-visible="false"
                      :series-defaults-labels-visible="true"
                      :series-defaults-labels-background="'transparent'"
                      :series-defaults-labels-template="labelTemplate"
                      :tooltip-template="tooltipTemplate"
                      :series-defaults-labels-position="'insideBase'"
                      :series="createdSeries"
                      :tooltip-visible="true"
                      :theme="'sass'"
                    />
                  </div>
                  <div
                    v-if="closedTotal > 0"
                    class="col-xl-6 col-lg-6 col-12"
                  >
                    <kendo-chart
                      ref="closedChart"                    
                      :title-text="`Tickets closed on Calendar Weeks (${closedTotal})`"
                      :title-position="'top'"
                      :chart-area-background="''"
                      :legend-visible="false"
                      :series-defaults-labels-visible="true"
                      :series-defaults-labels-background="'transparent'"
                      :series-defaults-labels-template="labelTemplate"
                      :tooltip-template="tooltipTemplate"
                      :series-defaults-labels-position="'insideBase'"
                      :series="closedSeries"
                      :tooltip-visible="true"
                      :theme="'sass'"
                    />
                  </div>
                </div>
              </div>            
            </div>
          </div>
        </div>
      </div>

      <div class="m-form__group form-group">
        <div class="m-radio-inline">
          <label class="m-radio">
            <input
              v-model="status"
              type="radio"
              :name="'statusRadioButton'"
              value="open"
            >{{ $t('freshdesk.openTickets') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="status"
              type="radio"
              :name="'statusRadioButton'"
              value="closed"
            >{{ $t('freshdesk.closedTickets') }}
            <span />
          </label>
        </div>
      </div>

      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(filteredTickets)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(filteredTickets)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="subjectLink"
          slot-scope="{props}"
        >
          <td
            class="alt-primary-color"
          >
            <a
              :href="`https://help.alturos.com/support/tickets/${props.dataItem.id}`"
              target="_blank"
            >{{ kgm_getNestedValue(props.field, props.dataItem) }}</a>
          </td>
        </template>
        <template
          slot="jiraIssueCell"
          slot-scope="{props}"
        >
          <td>
            <template v-if="props.dataItem.summary">
              <div
                v-for="(sum, key) in props.dataItem.summary"
                :key="`jI-${key}`"
              >
                <p v-if="sum.Status">
                  <a
                    class="alt-primary-color"
                    :href="jiraUrl(sum.Id)"
                  >{{ sum.Id }}</a> - {{ sum.Status }}
                </p>
              </div>
            </template>
          </td>
        </template>
        <template
          slot="releaseCell"
          slot-scope="{props}"
        >
          <td>
            <template v-if="props.dataItem.summary">
              <div
                v-for="(sum, key) in props.dataItem.summary"
                :key="`jR-${key}`"
              >
                <p v-if="sum.Release">
                  {{ sum.Id }} - {{ sum.Release }}
                </p>
              </div>
            </template>
          </td>
        </template>
        <template
          slot="fixedVersionCell"
          slot-scope="{props}"
        >
          <td>
            <template v-if="props.dataItem.summary">
              <div
                v-for="(sum, key) in props.dataItem.summary"
                :key="`jF-${key}`"
              >
                <p v-if="sum.FixedVersion">
                  {{ sum.Id }} - {{ sum.FixedVersion }}
                </p>
              </div>
            </template>
          </td>
        </template>
        <template
          slot="createdAtCell"
          slot-scope="{props}"
        >
          <td>
            {{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}
          </td>
        </template>
        <div
          slot="ticketSubjectFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="ticketSubjectFilter"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a subject"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('ticketSubjectFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="idFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="idFilter"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a ID"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('idFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="statusFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="statusSelect"
            :key="`statusSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(statusk, key) in statusList"
              :key="`statusListFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, statusk, 'statusSelect')"
            >
              {{ statusk }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.statusSelect != null && ($refs.statusSelect.value != null && $refs.statusSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('statusSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="priorityFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="prioritySelect"
            :key="`prioritySelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(priorityk, key) in priorityList"
              :key="`priorityListFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, priorityk, 'prioritySelect')"
            >
              {{ priorityk }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.prioritySelect != null && ($refs.prioritySelect.value != null && $refs.prioritySelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('prioritySelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="createdFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="createdFilter"
            :value="props.value"
            type="date"
            class="form-control mr-2 rounded-right"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('createdFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="contactFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="contactFilter"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an contact"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('contactFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="themeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="themeSelect"
            :key="`themeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(themek, key) in themeList"
              :key="`themeListFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, themek, 'themeSelect')"
            >
              {{ themek }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.themeSelect != null && ($refs.themeSelect.value != null && $refs.themeSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('themeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="impactFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="impactSelect"
            :key="`impactSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(impactk, key) in impactList"
              :key="`impactListFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, impactk, 'impactSelect')"
            >
              {{ impactk }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.impactSelect != null && ($refs.impactSelect.value != null && $refs.impactSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('impactSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="discussedFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="discussedSelect"
            :key="`discussedSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(typed, key) in ['to be discussed', 'not to be discussed']"
              :key="`discussedListFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, typed, 'discussedSelect')"
            >
              {{ typed }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.discussedSelect != null && ($refs.discussedSelect.value != null && $refs.discussedSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('discussedSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="agentFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="agentFilter"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a agent"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('agentFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="releaseFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="releaseFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a release"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('releaseFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="issueFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="issueFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a issue"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('issueFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="fixedFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="fixedFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a fixed"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('fixedFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="environmentFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="environmentSelect"
            :key="`environmentSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(environmentk, key) in environmentList"
              :key="`environmentListFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, environmentk, 'environmentSelect')"
            >
              {{ environmentk }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.environmentSelect != null && ($refs.environmentSelect.value != null && $refs.environmentSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('environmentSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { mapGetters } from 'vuex';
import { responsiveKendoGridMixin } from '@/mixins/responsiveKendoGridMixin.js';

export default {
  name: "FreshdeskUserTicketList",
  mixins: [
    kendoGridMixin,
    dateTimeMixin,
    urlParseMixin,
    responsiveKendoGridMixin
  ],
  props: {
    companyId: {
      type: String,
      required: false,
      default: null
    },
    agentId: {
      type: String,
      required: false,
      default: null
    },
    yearOrMonth: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      labelTemplate: '#= category #',
      tooltipTemplate: '#= category # - #= value #',
      loading: false,
      colapse: false,
      freshdeskTickets: null,
      filteredTickets: [],
      status: 'open',
      kgm_take: 50,
      kgm_columns: [
        {
          field: 'needsToBeDiscussed',
          filterable: true,
          filter: 'text',
          title: 'Needs to be discussed',
          filterCell: 'discussedFilter'
        },
        {
          field: 'id',
          filterable: true,
          filter: 'text',
          title: 'ID',
          filterCell: 'idFilter',
          hideOn: ['xlDown'],
        },
        {
          field: 'subject',
          filterable: true,
          filter: 'text',
          title: this.$t('freshdesk.subject'),
          filterCell: 'ticketSubjectFilter',
          cell: 'subjectLink',
        },
         {
          field: 'createdAt',
          filterable: true,
          filter: 'date',
          title: this.$t('freshdesk.createdAt'),
          filterCell: 'createdFilter',
          cell: 'createdAtCell'
        },
        {
          field: 'environment',
          filterable: true,
          filter: 'text',
          title: this.$t('freshdesk.environment'),
          filterCell: 'environmentFilter',
          hideOn: ['mdDown'],
        },
        {
          field: 'contactName',
          filterable: true,
          filter: 'text',
          title: this.$t('freshdesk.contact'),
          filterCell: 'contactFilter'
        },
        {
          field: 'agentName',
          filterable: true,
          filter: 'text',
          title: 'Agent',
          filterCell: 'agentFilter',
          hideOn: ['xlDown'],
        },
        {
          field: 'statusText',
          filterable: true,
          filter: 'text',
          title: 'Status',
          filterCell: 'statusFilter'
        },
        {
          field: 'priorityName',
          filterable: true,
          filter: 'text',
          title: this.$t('priority'),
          filterCell: 'priorityFilter'
        },
        {
          field: 'theme',
          filterable: true,
          filter: 'text',
          title: this.$t('freshdesk.topic'),
          filterCell: 'themeFilter',
          hideOn: ['lgDown'],
        },
        {
          field: 'impact',
          filterable: true,
          filter: 'text',
          title: this.$t('freshdesk.impact'),
          filterCell: 'impactFilter',
          hideOn: ['lgDown'],
        },
        {
          field: 'issueField',
          filterable: true,
          filter: 'text',
          title: 'Jira Issue',
          filterCell: 'issueFilter',
          cell: 'jiraIssueCell'
        },
        {
          field: 'releaseField',
          filterable: true,
          filter: 'text',
          title: 'Release',
          hideOn: ['mdDown'],
          filterCell: 'releaseFilter',
          cell: 'releaseCell'
        },
        {
          field: 'fixedField',
          filterable: true,
          filter: 'text',
          title: 'Fixed Version',
          hideOn: ['mdDown'],
          filterCell: 'fixedFilter',
          cell: 'fixedVersionCell'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl'
    ]),
    statusList () {
      if (!this.filteredTickets) {
        return [ "" ];
      }
      let statusList = Array.from(new Set(this.filteredTickets.map(o=>o.statusText)));
      statusList = statusList.filter(function (e) {return e});
      return statusList.sort();
    },
    priorityList () {
      if (!this.filteredTickets) {
        return [ "" ];
      }
      let priorityList = Array.from(new Set(this.filteredTickets.map(o=>o.priorityName)));
      priorityList = priorityList.filter(function (e) {return e});
      return priorityList.sort();
    },
    prioSeries () {
      if (!this.filteredTickets) {
        return [ {
          type: 'pie',
          startAngle: 0,
          data: []
        } ];
      }
      let prioList = this.filteredTickets.map(o=>o.priorityName);
      return [ {
          type: 'pie',
          startAngle: 0,
          data: this.getSeries(prioList)
        } ];
    },
    prioTotal () {
      return this.prioSeries[0].data.map(x => x.value).reduce((a, b) => a + b, 0)
    },
    themeList () {
      if (!this.filteredTickets) {
        return [ "" ];
      }
      let themeList = Array.from(new Set(this.filteredTickets.map(o=>o.theme)));
      themeList = themeList.filter(function (e) {return e});
      return themeList.sort();
    },
    topicSeries () {
      if (!this.filteredTickets) {
        return [ {
          type: 'pie',
          startAngle: 0,
          data: []
        } ];
      }
      let topicList = this.filteredTickets.map(o=>o.theme);
      return [ {
          type: 'pie',
          startAngle: 0,
          data: this.getSeries(topicList)
        } ];
    },
    topicTotal () {
      return this.topicSeries[0].data.map(x => x.value).reduce((a, b) => a + b, 0)
    },
    impactList () {
      if (!this.filteredTickets) {
        return [ "" ];
      }
      let impactList = Array.from(new Set(this.filteredTickets.map(o=>o.impact)));
      impactList = impactList.filter(function (e) {return e});
      return impactList.sort();
    },
    createdSeries () {
      let tickets = this.freshdeskTickets.filter(x => x.status != 4 && x.status != 5);
      if (!tickets) {
        return [ {
          type: 'pie',
          startAngle: 0,
          data: []
        } ];
      }

      let data = [];
      for(let i = 0; i > -4; i--) {
        let cw = this.moment().add(i, 'w').isoWeek();
        data.push({
          category: `CW ${cw}`,
          value: tickets.filter(x => this.toMoment(x.createdAt).isoWeek() == cw).length,
      });
      }

      return [ {
          type: 'pie',
          startAngle: 0,
          data: data
        } ];
    },
    createdTotal () {
      return this.createdSeries[0].data.map(x => x.value).reduce((a, b) => a + b, 0)
    },
    closedSeries () {
      let tickets = this.freshdeskTickets.filter(x => x.status == 4 || x.status == 5);
      if (!tickets) {
        return [ {
          type: 'pie',
          startAngle: 0,
          data: []
        } ];
      }

      let data = [];
      for(let i = 0; i > -4; i--) {
        let cw = this.moment().add(i, 'w').isoWeek();
        data.push({
          category: `CW ${cw}`,
          value: tickets.filter(x => x.closedAt && this.toMoment(x.closedAt).isoWeek() == cw).length,
        });
      }
      return [ {
          type: 'pie',
          startAngle: 0,
          data: data
        } ];
    },
    closedTotal () {
      return this.closedSeries[0].data.map(x => x.value).reduce((a, b) => a + b, 0)
    },
    environmentList () {
      if (!this.filteredTickets) {
        return [ "" ];
      }
      let environmentList = Array.from(new Set(this.filteredTickets.map(o=>o.environment)));
      environmentList = environmentList.filter(function (e) {return e});
      return environmentList.sort();
    },
  },
  watch: {
    companyId () {
      this.getInstallationTickets();
    },
    agentId () {
      this.getInstallationTickets();
    },
    status () {
      this.filterPerStatus();
    },
    yearOrMonth () {
      this.getInstallationTickets();
    }
  },
  created () {
    this.getInstallationTickets();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getSeries (list) {
      list = list.filter(function (e) {return e});
      let data = [];
      let counts = {};
      list.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
      const sortable = Object.entries(counts)
        .sort(([,a],[,b]) => a-b).reverse()
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

      let count = 0;
      for(let item in sortable) {
        if(count < 5) {
          data.push({
              category: item.length > 15 ? `${item.substring(0, 15)}...` : item,
              value: sortable[item]
          });
        }
        else {
          if(data.length == 5) {
            data.push({
              category: 'Other',
              value: sortable[item],
          });
          }
          else {
            data[5].value += sortable[item]
          }
        }
        count++;
      }
      return data;
    },
    handleResize () {
      this.responsiveKendoGridMixin_refresh(this.$refs.typeChart);
      this.responsiveKendoGridMixin_refresh(this.$refs.prioChart);
      this.responsiveKendoGridMixin_refresh(this.$refs.topicChart);
      this.responsiveKendoGridMixin_refresh(this.$refs.createdChart);
      if(this.status == 'closed') {
        this.responsiveKendoGridMixin_refresh(this.$refs.closedChart);
      }
    },
    setColapse () {
      this.colapse = !this.colapse
       setTimeout(this.handleResize, 100);
    },
    jiraUrl (endpoint) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ endpoint }`);
    },
    filterPerStatus ()
    {
      if(this.status == 'open') {
        this.filteredTickets = this.freshdeskTickets.filter(x => x.status != 4 && x.status != 5);
      }
      else if(this.status == 'closed') {
        this.filteredTickets = this.freshdeskTickets.filter(x => x.status == 4 || x.status == 5);
      }
      else {
        this.filteredTickets = this.freshdeskTickets;
      }
    },
    parseSummary (data) {
      try {
        return JSON.parse(data)
      }
      catch {
        return null
      }
    },
    getInstallationTickets () {
      this.loading = true;
      var url = "/Freshdesk/GetLoggedInUserTickets"

      if(this.companyId) {
        url += `?companyId=${this.companyId}&yearOrMonth=${this.yearOrMonth}`
      }
      else if(this.agentId) {
        url += `?agentId=${this.agentId}&yearOrMonth=${this.yearOrMonth}`
      }
      else {
        url += `?yearOrMonth=${this.yearOrMonth}`
      }
  
      this.axios.get(url)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.freshdeskTickets = response.data;
        this.freshdeskTickets = this.freshdeskTickets.map(x => (
          {
            id: x.id,
            subject: x.subject,
            priorityName: x.priorityName,
            statusText: x.statusText,
            status: x.status,
            contactName: x.contactName,
            createdAt: x.created_at,
            closedAt: x.stats ? x.stats.closed_at : null,
            type: x.type,
            agentName: x.agentName,
            jiraIssue: x.custom_fields.cf_jiraissue,
            theme: x.custom_fields.cf_themenbereich,
            environment: x.custom_fields.cf_environment,
            impact: x.custom_fields.cf_impact_on_your_business,
            release: x.custom_fields.cf_release,
            jiraStatus: x.custom_fields.cf_jira_status,
            needsToBeDiscussed: x.custom_fields.cf_needs_to_be_discussed ? 'to be discussed' : 'not to be discussed',
            summary: x.custom_fields.cf_issue_summary ? this.parseSummary(x.custom_fields.cf_issue_summary) : null
          }
        ));

        this.freshdeskTickets.forEach(e => {
          if(e.summary && e.summary.length > 0) {
            e.releaseField = e.summary.filter(x => x.Release).map(x => `${x.Id} - ${x.Release}`).join('*')
            e.fixedField = e.summary.filter(x => x.FixedVersion).map(x => `${x.Id} - ${x.FixedVersion}`).join('*')
            e.issueField = e.summary.filter(x => x.Status).map(x => `${x.Id} - ${x.Status}`).join('*')
          }
        });

        this.filterPerStatus();
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.freshdeskUserTicketList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>